/** @jsxImportSource @emotion/react */
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { css } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useEffect, useState, VFC } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router";
import { CountryPath } from "src/entities/CountryPath";
import {
  GetArticlesV2,
  GetArticlesV2Variables,
} from "src/entities/v2/GetArticlesV2";
import { useDownload } from "src/hooks/useDownload";
import { useFetch } from "src/hooks/useFetch";
import getCountryName from "src/utils/getCountryName";
import PageContentsWrapper from "../components/page/PageContentsWrapper";
import PageHeader from "../components/page/PageHeader";
import PageWrapper from "../components/page/PageWrapper";
import StyledTable from "../components/table/StyledTable";
import StyledTableBody from "../components/table/StyledTableBody";
import StyledTableCell from "../components/table/StyledTableCell";
import StyledTableHeader from "../components/table/StyledTableHeader";
import StyledTableHeaderRow from "../components/table/StyledTableHeaderRow";
import StyledTableRow from "../components/table/StyledTableRow";

interface Props {
  signout: (navigate: NavigateFunction) => boolean;
  lang: string;
  country: CountryPath;
  publisherId: string;
  isPublisherLoading: boolean;
}

/**
 * Sort by
 */
type SortBy = "title" | "published_at" | "status" | "updated_at";
/**
 * Sort order
 */
type SortOrder = "asc" | "desc";

const ReportPage: VFC<Props> = (props: Props) => {
  const navigate = useNavigate();

  const [t, i18n] = useTranslation();
  const [sortBy, setSortBy] = useState<SortBy>("published_at");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const { enqueueSnackbar } = useSnackbar();
  const { download } = useDownload();

  useEffect(() => {
    if (props.lang) {
      i18n.changeLanguage(props.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n, props.lang]);

  const { data: articles, isLoading: isArticlesLoading } =
    useFetch<GetArticlesV2>({
      url: `${process.env.REACT_APP_DOMAIN_V2}/api/v2/articles`,
      headers: {
        country: props.country,
      },
      skip: props.publisherId === undefined,
      onError: () => {
        enqueueSnackbar(t("error.login_again"), {
          variant: "error",
        });
        props.signout(navigate);
      },
    });

  const sortedArticle =
    articles && articles.articles && sortArticles(articles, sortBy, sortOrder);

  return (
    <PageWrapper>
      <div className="flex items-center justify-between">
        <PageHeader
          title={t("report.page_header", {
            country: getCountryName(props.country),
          })}
        />
        <a
          target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLSd4G7lu75PBMYc78uXZfMH8xgFVFraWr4oSIsSYk5tksrHwoQ/viewform?usp=dialog"
          rel="noreferrer"
          className="text-blue-600 text-lg font-semibold"
        >
          Give us feedback
        </a>
      </div>
      <PageContentsWrapper>
        {props.isPublisherLoading || isArticlesLoading ? (
          <div className="flex h-full w-full justify-center">
            <CircularProgress />
          </div>
        ) : articles && articles?.articles.length > 0 ? (
          <StyledTable>
            <StyledTableHeader>
              <StyledTableHeaderRow>
                <th
                  onClick={() => {
                    setSortBy("title");
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  }}
                  className="px-4 w-40% border-r hover:cursor-pointer"
                  align="left"
                >
                  {t("report.table.title")}
                  {sortBy === "title" ? (
                    sortOrder === "asc" ? (
                      <ArrowUpwardIcon fontSize="small" />
                    ) : (
                      <ArrowDownwardIcon fontSize="small" />
                    )
                  ) : null}
                </th>
                <th
                  onClick={() => {
                    setSortBy("published_at");
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  }}
                  className="px-4 w-20% border-r hover:cursor-pointer"
                  align="left"
                >
                  {t("report.table.post_date")}
                  {sortBy === "published_at" ? (
                    sortOrder === "asc" ? (
                      <ArrowUpwardIcon fontSize="small" />
                    ) : (
                      <ArrowDownwardIcon fontSize="small" />
                    )
                  ) : null}
                </th>
                <th className="px-4 w-20% border-r" align="left">
                  {t("report.table.publication_report")}
                </th>
                <th className="px-4 w-20% border-r" align="left">
                  {t("report.table.feedback_report")}
                </th>
              </StyledTableHeaderRow>
            </StyledTableHeader>
            <StyledTableBody>
              {sortedArticle?.map((article) => {
                return (
                  <StyledTableRow key={article.id}>
                    <StyledTableCell>
                      <p className="truncate">{article.title}</p>
                    </StyledTableCell>
                    <StyledTableCell>
                      <p style={{ fontSize: 14 }}>
                        {DateTime.fromISO(article.published_at).toFormat(
                          "MMM dd yyyy h:mm a"
                        )}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" button>
                      {article.exist_publication_report ? (
                        <button
                          className="flex items-center justify-center bg-primary text-white rounded-md px-4 py-2 hover:bg-blue-800"
                          type="button"
                          onClick={() => {
                            download({
                              type: "publication",
                              articleId: article.id,
                            });
                          }}
                        >
                          {t("report.button.download")}
                        </button>
                      ) : isBefore2024(
                          DateTime.fromISO(article.published_at)
                        ) ? (
                        <p className="truncate">Already sent via Email</p>
                      ) : (
                        <p className="truncate">Processing</p>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center" button>
                      {article.exist_feedback_report ? (
                        <button
                          className="flex items-center justify-center bg-primary text-white rounded-md px-4 py-2 hover:bg-blue-800"
                          type="button"
                          onClick={() => {
                            download({
                              type: "feedback",
                              articleId: article.id,
                            });
                          }}
                        >
                          {t("report.button.download")}
                        </button>
                      ) : isBefore2024(
                          DateTime.fromISO(article.published_at)
                        ) ? (
                        <p className="truncate">Already sent via Email</p>
                      ) : (
                        <p className="truncate">Processing</p>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </StyledTableBody>
            {/* <StyledTableFooter /> */}
          </StyledTable>
        ) : articles ? (
          <div css={addDescriptionStyle}>
            <div className="mb-2 font-semibold whitespace-pre-wrap">
              {t("report.no_press_release")}
            </div>
          </div>
        ) : (
          <div>
            <div className="mb-2 font-semibold">
              {t("report.display_error")}
            </div>
          </div>
        )}
      </PageContentsWrapper>
    </PageWrapper>
  );
};

function sortArticles(
  articles: GetArticlesV2,
  sortBy: SortBy,
  order: SortOrder
): GetArticlesV2Variables[] {
  if (sortBy === "published_at") {
    if (order === "asc") {
      return articles.articles.sort((a, b) => {
        return (
          DateTime.fromISO(a.published_at).toMillis() -
          DateTime.fromISO(b.published_at).toMillis()
        );
      });
    }
    if (order === "desc") {
      return articles.articles.sort((a, b) => {
        return (
          DateTime.fromISO(b.published_at).toMillis() -
          DateTime.fromISO(a.published_at).toMillis()
        );
      });
    }
  } else if (sortBy === "updated_at") {
    if (order === "asc") {
      return articles.articles.sort((a, b) => {
        return (
          DateTime.fromISO(a.updated_at).toMillis() -
          DateTime.fromISO(b.updated_at).toMillis()
        );
      });
    }
    if (order === "desc") {
      return articles.articles.sort((a, b) => {
        return (
          DateTime.fromISO(b.updated_at).toMillis() -
          DateTime.fromISO(a.updated_at).toMillis()
        );
      });
    }
  } else if (sortBy === "title") {
    if (order === "asc") {
      return articles.articles.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
    }
    if (order === "desc") {
      return articles.articles.sort((a, b) => {
        return b.title.localeCompare(a.title);
      });
    }
  }
  return articles.articles;
}

function isBefore2024(date: DateTime): boolean {
  const comparisonDate = DateTime.local(2023, 12, 31);
  return date <= comparisonDate;
}

const addDescriptionStyle = css`
  flex: 1;
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 8px;
  border-color: #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  font-size: 18px;
`;

export default ReportPage;
